import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"
import { CLOUDINARY_URL } from "../constants"
import smoothscroll from "smoothscroll-polyfill"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import PageHeader from "../components/pageHeader"
import InstagramFeed from "../components/instagram"
import NowPlayingCard from "../components/nowPlayingCard"

import NavigationArrowWhiteIcon from "../images/navigation_arrow--white.svg"

import "./culture.scss"

const JobOpening = ({ slug, title, excerpt }) => (
  <div className="career">
    <header className="career__title">
      <h2>{title}</h2>
    </header>
    <div className="career__wrapper">
      <div className="career__description">
        <div dangerouslySetInnerHTML={{ __html: excerpt.content }} />
        <Link to={`/culture/${slug}`} className="btn career__btn">
          Learn more
        </Link>
      </div>
    </div>
  </div>
)

const CareersList = ({ jobOpenings }) => {
  // const categories = [...new Set(jobOpenings.map(job => job.category.selected.label))]
  const careerListContent = []
  const categories = Array.from(
    new Set(jobOpenings.map(job => job.category.selected.label))
  )

  categories.forEach(label => {
    const jobsByCategory = jobOpenings.filter(
      job => job.category.selected.label === label
    )

    careerListContent.push(
      <div key={label} className="careers__category">
        <header className="careers__category-title">
          <h2>{label}</h2>
        </header>

        <div className="careers__list">
          {jobsByCategory.length > 0 &&
            jobsByCategory.map(job => (
              <JobOpening
                key={job.id}
                slug={job.slug}
                title={job.title}
                excerpt={job.excerpt}
              />
            ))}
        </div>
      </div>
    )
  })

  return careerListContent
}

const CulturePage = ({ data, location }) => {
  const {
    culturePageContent: {
      gridImageTopRight,
      gridImageCenterLeft,
      gridImageBottomRight,
      gridTextBlock1,
      gridTextBlock2,
      title,
      subtitle,
      careersContent,
    },
    jobOpenings,
  } = data.craft
  const { openMicPosts } = data.craft
  const { heroImage } = openMicPosts[0]
  const smoothScroll = () => {
    smoothscroll.polyfill()
    if (document !== "undefined") {
      document.querySelector("#careers").scrollIntoView({ behavior: "smooth" })
    }
  }

  let readMore = useRef(null)
  let openMicLogo = useRef(null)
  const [openMicState, setOpenMicState] = useState(false)

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(() => {
    setOpenMicState(!openMicState)
  }, 5000)

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
    clearInterval()
  }, [])

  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: "culture" }}>
        <script src={withPrefix("/scripts/nowPlaying.js")} />
      </Helmet>
      <SEO title="Our Culture" location={location} />

      <PageHeader
        button={
          <a
            className="btn btn--solid heading__btn culture-header__btn hide-on--mobile"
            onClick={smoothScroll}
          >
            Open Positions
            <NavigationArrowWhiteIcon />
          </a>
        }
        className={`culture-header ${loaded && "loaded"}`}
        style={{
          backgroundImage: `url(${withPrefix(
            "/images/culture/bg_ourCulture.jpg"
          )})`,
        }}
      >
        <>
          <h1>{title}</h1>
          <div className="heading__subtext heading__subtext--bordered">
            <h2>{subtitle}</h2>
          </div>
        </>
      </PageHeader>

      <section id="grid" className="layout__section culture-grid">
        <div className="content-grid">
          {gridTextBlock1 && gridTextBlock1.content && (
            <div className="content-grid__cell content-grid__align--center content-grid__cell--half">
              <div
                className="content-grid__cell--text"
                dangerouslySetInnerHTML={{ __html: gridTextBlock1.content }}
              />
            </div>
          )}

          {gridImageTopRight.length > 0 && (
            <div className="content-grid__cell content-grid__cell--half">
              <img
                className="cover"
                src={`${CLOUDINARY_URL}/${gridImageTopRight[0].filename}`}
                alt={
                  gridImageTopRight[0].imageCaption
                    ? gridImageTopRight[0].imageCaption.content
                    : gridImageTopRight[0].title
                }
              />
            </div>
          )}

          {gridImageCenterLeft.length > 0 && (
            <div className="content-grid__cell content-grid__cell--half">
              <img
                className="cover"
                src={`${CLOUDINARY_URL}/c_scale,w_958/c_crop,g_center,h_491,w_958/${
                  gridImageCenterLeft[0].filename
                }`}
                alt={
                  gridImageCenterLeft[0].imageCaption
                    ? gridImageCenterLeft[0].imageCaption.content
                    : gridImageCenterLeft[0].title
                }
              />
            </div>
          )}

          <div className="content-grid__cell content-grid__cell--instagram content-grid__cell--quarter">
            <InstagramFeed />
          </div>

          <NowPlayingCard />

          {gridTextBlock2 && gridTextBlock2.content && (
            <div className="content-grid__cell content-grid__align--center content-grid__cell--half">
              <div
                className="content-grid__cell--text"
                dangerouslySetInnerHTML={{ __html: gridTextBlock2.content }}
              />
            </div>
          )}

          {gridImageBottomRight.length > 0 && (
            <div className="content-grid__cell content-grid__cell--quarter">
              <img
                src={`${CLOUDINARY_URL}/${gridImageBottomRight[0].filename}`}
                alt={
                  gridImageBottomRight[0].imageCaption
                    ? gridImageBottomRight[0].imageCaption.content
                    : gridImageBottomRight[0].title
                }
              />
            </div>
          )}
          <div className="content-grid__cell content-grid__cell--quarter">
            <Link to="/open-mic">
              <div className="culture__open-mic-post">
                <div className="culture__open-mic-post-container">
                  <img
                    className="culture__open-mic-post-image"
                    src={`https://res.cloudinary.com/amplifiedbydesign/image/upload/x_${Math.floor(
                      heroImage[0].focalPoint.x *
                        heroImage[0].width *
                        (225 / heroImage[0].height)
                    )},y_${Math.floor(
                      heroImage[0].focalPoint.y *
                        heroImage[0].height *
                        (225 / heroImage[0].height)
                    )}/${heroImage[0].filename}`}
                  />
                  <div className="culture__open-mic-post-overlay">
                    <h2
                      className={openMicState ? "fade-in" : "fade-out"}
                      ref={readMore}
                    >
                      Read More
                    </h2>
                    <img
                      ref={openMicLogo}
                      className={openMicState ? "fade-out" : "fade-in"}
                      src={`${withPrefix(
                        "/images/open-mic/open_mic-logo.svg"
                      )}`}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>

      {careersContent.length > 0 && (
        <section
          id="careers"
          className="layout__section background--accent careers"
        >
          <div className="layout__main">
            <div className="layout__inner layout__inner--full-width">
              <header className="careers__heading font--center">
                <h2>{careersContent[0].careersSectionSectionTitle}</h2>
              </header>

              {careersContent[0].careersSectionDescription &&
                careersContent[0].careersSectionDescription.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        careersContent[0].careersSectionDescription.content,
                    }}
                  />
                )}

              <CareersList jobOpenings={jobOpenings} />
              {/* {console.log(jobOpenings)} */}

              {careersContent[0].careersSectionNotFoundMessage &&
                careersContent[0].careersSectionNotFoundMessage.content && (
                  <div
                    className="careers__msg"
                    dangerouslySetInnerHTML={{
                      __html:
                        careersContent[0].careersSectionNotFoundMessage.content,
                    }}
                  />
                )}
            </div>
          </div>
          {/* <NowPlayingScript /> */}
        </section>
      )}
    </Layout>
  )
}

export const getCulturePageContentQuery = graphql`
  query getCulturePageContentQuery {
    craft {
      culturePageContent: entry(section: culture) {
        ... on Craft_Culture {
          id
          slug
          title
          subtitle
          gridTextBlock1 {
            content
          }
          gridTextBlock2 {
            content
          }
          gridImageTopRight {
            ... on Craft_CloudImagesVolume {
              id
              filename
              imageCaption {
                content
              }
              title
            }
          }
          gridImageCenterLeft {
            ... on Craft_CloudImagesVolume {
              id
              filename
              focalPoint {
                x
                y
              }
              height
              imageCaption {
                content
              }
              title
              width
            }
          }
          gridImageBottomRight {
            ... on Craft_CloudImagesVolume {
              id
              filename
              imageCaption {
                content
              }
              title
            }
          }
          careersContent {
            ... on Craft_CareersContentCareersSection {
              careersSectionSectionTitle
              careersSectionDescription {
                content
              }
              careersSectionNotFoundMessage {
                content
              }
            }
          }
        }
      }
      jobOpenings: entries(section: [jobs]) {
        ... on Craft_JobsJobOpening {
          id
          uri
          slug
          title
          category: careerCategory_FieldData {
            selected {
              label
            }
          }
          heroImage {
            ... on Craft_HeroVolume {
              id
              filename
              focalPoint {
                x
                y
              }
              height
              width
              heroImageSize_FieldData {
                selected {
                  value
                }
              }
              heroImageSizeModifier_FieldData {
                selected {
                  label
                }
              }
              title
            }
          }
          color
          excerpt {
            content
          }
          contentBody {
            ... on Craft_ContentBodyText {
              __typename
              id
              richText {
                content
              }
            }
          }
        }
      }
      openMicPosts: entries(section: [openMicPosts]) {
        ... on Craft_OpenMicPosts {
          id
          slug
          heroImage {
            ... on Craft_HeroVolume {
              filename
              focalPoint {
                x
                y
              }
              height
              title
              width
              heroImageSize
              heroImageSizeModifier
            }
          }
        }
      }
    }
  }
`

export default CulturePage
