import React from "react"
import { withPrefix } from "gatsby"

class NowPlayingCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDetails: false,
    }
  }

  toggleDetails = () => {
    this.setState(({ showDetails }) => ({ showDetails: !showDetails }))
  }

  render() {
    return (
      <div
        className="content-grid__cell content-grid__cell--lastfm content-grid__cell--quarter"
        onMouseOver={this.toggleDetails}
        onMouseOut={this.toggleDetails}
      >
        <img
          className="currently-playing cover"
          src={withPrefix("/images/culture/now-playing-placeholder.svg")}
          alt="current song, current album"
        />
        <div className="content-grid__cell--lastfm__logo">
          <svg
            height="27"
            viewBox="0 0 23 27"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m21.2497403.77945063c-.3403716-.33664575-.7907319-.48334366-1.2306901-.39892373l-11.33007662 2.14684707c-.73633156.13943215-1.27736028.87984282-1.27736028 1.74827197v12.32357166c-.61519526-.2616071-1.29263336-.4084793-2.00507328-.4084793-2.78421169 0-5.04939716 2.2163908-5.04939716 4.9403471 0 2.7239524 2.26518206 4.9403432 5.04939716 4.9403432 2.78421509 0 5.04909758-2.2163908 5.04909758-4.9403432v-15.34901363l8.2860523-1.5700904v10.24051593c-.6152701-.2616744-1.2928171-.4086101-2.0053694-.4086101-2.7842151 0-5.0490976 2.2163909-5.0490976 4.9403432 0 2.7239524 2.2648859 4.9403471 5.0490976 4.9403471s5.0493938-2.2163908 5.0493938-4.9403471v-16.85543152c0-.51967248-.1959019-1.01305114-.535974-1.34934825z"
              fill="#a871ff"
              transform="translate(.5 .5)"
            />
          </svg>
        </div>
        <div
          className={`content-grid__cell--lastfm__overlay ${
            this.state.showDetails ? "show" : ""
          }`}
        >
          <h1>Now Playing...</h1>
          <h2 className="track track__title" />
          <h2 className="track track__name" />
        </div>
      </div>
    )
  }
}

export default NowPlayingCard
